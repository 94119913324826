import React from "react";
import BasicLayout from "./layouts/BasicLayout";
import Login from "./views/Login/Login";
import { Redirect } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import Dashboard from "./views/Dashboard/Dashboard";
import Permission from "./views/Permission/Permission";
import CreatePermission from "./views/Permission/CreatePermission";
import EditPermission from "./views/Permission/EditPermission";
import ViewPermission from "./views/Permission/ViewPermission";
import Role from "./views/Role/Role";
import CreateRole from "./views/Role/CreateRole";
import EditRole from "./views/Role/EditRole";
import Setting from "./views/Setting/Setting";
import CreateSetting from "./views/Setting/CreateSetting";
import EditSetting from "./views/Setting/EditSetting";
import MasterItem from "./views/MasterItem/MasterItem";
import MasterKanban from "./views/MasterKanban/MasterKanban";
import EditKanbanWIP from "./views/MasterKanban/EditKanbanWIP";
import EditKanbanFinishGoods from "./views/MasterKanban/EditKanbanFinishGoods";
import EditKanbanSupplier from "./views/MasterKanban/EditKanbanSupplier";
import CreateMasterKanban from "./views/MasterKanban/CreateMasterKanban";
import MasterUserInternal from "./views/MasterUserInternal/MasterUserInternal";
import CreateMasterUserInternal from "./views/MasterUserInternal/CreateMasterUserInternal";
import EditMasterUserInternal from "./views/MasterUserInternal/EditMasterUserInternal";
import MasterUserExternal from "./views/MasterUserExternal/MasterUserExternal";
import CreateMasterUserExternal from "./views/MasterUserExternal/CreateMasterUserExternal";
import EditMasterUserExternal from "./views/MasterUserExternal/EditMasterUserExternal";
import MasterSupplier from "./views/MasterSupplier/MasterSupplier";
import MasterWarehouseLocation from "./views/MasterWarehouseLocation/MasterWarehouseLocation";
import MasterProductStructure from "./views/MasterProductStructure/MasterProductStructure";
import SPMaterialOrder from "./views/SPMaterialOrder/SPMaterialOrder";
import SPDNMaterial from "./views/SPDNMaterial/SPDNMaterial";
import SPListGRMaterial from "./views/SPListGRMaterial/SPListGRMaterial";
import MStockHK from "./views/MStockHK/MStockHK";
import MasterCustomer from "./views/MasterCustomer/MasterCustomer";
import CreateSPDNMaterial from "./views/SPDNMaterial/CreateSPDNMaterial";
import EditSPDNMaterial from "./views/SPDNMaterial/EditSPDNMaterial";
import WScanReceiving from "./views/WScanReceiving/WScanReceiving";
import SPKanbanGenerate from "./SPKanbanGenerate/SPKanbanGenerate";
import CreateMasterWarehouseLocation from "./views/MasterWarehouseLocation/CreateMasterWarehouseLocation";
import EditMasterWarehouseLocation from "./views/MasterWarehouseLocation/EditMasterWarehouseLocation";
import SPHistoryReceiving from "./views/SPHistoryReceiving";
import SPScanReceivingMaterial from "./views/SPScanReceivingMaterial/SPScanReceivingMaterial";
import SPScanOutgoingInternal from "./views/SPScanOutgoingInternal.js/SPScanOutgoingInternal";
import SPScanOutgoingSupplier from "./views/SPScanOutgoingSupplier/SPScanOutgoingSupplier";
import SPHistoryOutgoingSupplier from "./views/SPHistoryOutgoingSupplier/SPHistoryOutgoingSupplier";
import MasterGrouping from "./views/MasterGrouping/MasterGrouping";
import CreateMasterGrouping from "./views/MasterGrouping/CreateMasterGrouping";
import EditMasterGrouping from "./views/MasterGrouping/EditMasterGrouping";
import SPSHistoryOutgoingInternal from "./views/SPSHistoryOutgpingInternal/SPSHistoryOutgoingInternal";
import ScanStockOpname from "./views/ScanStockOpname/ScanStockOpname";
import HistoryStockOpname from "./views/HistoryStockOpname/HistoryStockOpname";
import PScanProduksi from "./views/PScanProduksi/PScanProduksi";
import PHistoryScanProduksi from "./views/PHistoryScanProduksi/PHistoryScanProduksi";
import PHistoryScanKanbanNG from "./views/PHistoryScanKanbanNG/PHistoryScanKanbanNG";
import PScanWipResult from "./views/PScanWipResult/PScanWipResult";
import PPrintWipResult from "./views/PPrintWipResult/PPrintWipResult";
import PScanFinishGoods from "./views/PScanFinishGoods/PScanFinishGoods";
import PPrintFinishGoods from "./views/PPrintFinishGoods/PPrintFinishGoods";
import EditPDUploadDNMaterial from "./views/PDUploadDNMaterial/EditPDUploadDNMaterial";
import PDUploadDNMaterial from "./views/PDUploadDNMaterial/PDUploadDNMaterial";
import PDScanDNManifest from "./views/PDScanDNManifest/PDScanDNManifest";
import PDHistoryDNManifest from "./views/PDHistoryDNManifest/PDHistoryDNManifest";
import RReportTracebility from "./views/RReportTracebility/RReportTracebility";
import RReportEKanban from "./views/RReportEKanban/RReportEKanban";
import SPDetailsMaterialOrder from "./views/SPDetailsMaterialOrder.js/SPDetailsMaterialOrder";
import EditSPKanbanGenerate from "./SPKanbanGenerate/EditSPKanbanGenerate";
import EditHistoryScanProduksi from "./views/PHistoryScanProduksi/EditHistoryScanProduksi";
import DetailsScanProduksi from "./views/PScanProduksi/DetailsScanProduksi";
import DetailsScanReceiving from "./views/WScanReceiving/DetailsScanReceiving";
import DetailsScanOutgoingSupplier from "./views/SPScanOutgoingSupplier/DetailsScanOutgoingSupplier";
import ConfirmSPDNMaterial from "./views/SPDNMaterial/ConfirmSPDNMaterial";
import EditSPDetailsMaterialOrder from "./views/SPDetailsMaterialOrder.js/EditSPDetailsMaterialOrder";
import MasterShift from "./views/MasterShift/MasterShift";
import CreateMasterShift from "./views/MasterShift/CreateMasterShift";
import EditMasterShift from "./views/MasterShift/EditMasterShift";
import SPMaterialOrderNonPO from "./views/SPMaterialOrder/SPMaterialOrderNonPO";
import ProductionRequest from "./views/SPProductionRequest/SPProductionRequest";
import SPProductionRequest from "./views/SPProductionRequest/SPProductionRequest";
import PHistoryWipResult from "./views/PHistoryWipResult/PHistoryWipResult";
import PHistoryFGResult from "./views/PHistoryFGResult/PHistoryFGResult";
import DashboardInventory from "./views/Dashboard/DashboardInventory";
import DashboardKanbanOrder from "./views/Dashboard/DashboardKanbanOrder";
import DashboardKanbanReceiving from "./views/Dashboard/DashboardKanbanReceiving";
import DashboardKanbanPending from "./views/Dashboard/DashboardKanbanPending";
import MasterWeeklyForecast from "./views/MasterWeeklyForecast/MasterWeeklyForecast";
import SPMatching from "./views/SPMatching/SPMatching";
import SPHistoryMatching from "./views/SPHistoryMatching/SPHistoryMatching";
import Matrix from "./views/Matrix/Matrix";
import CreateMatrix from "./views/Matrix/CreateMatrix";
import EditMatrix from "./views/Matrix/EditMatrix";
import Shipment from "./views/MasterShipment/MasterShipment";
import MasterShipment from "./views/MasterShipment/MasterShipment";
import SPHistoryDNMaterial from "./views/SPDNMaterial/SPHistoryDNMaterial";

export default [
  {
    path: "/",
    layout: BasicLayout,
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: BasicLayout,
    component: Login,
    exact: true,
  },
  {
    path: "/dashboard",
    layout: FullLayout,
    component: Dashboard,
    exact: true,
  },
  {
    path: "/dashboard-inventory",
    layout: FullLayout,
    component: DashboardInventory,
    exact: true,
  },
  {
    path: "/dashboard-kanban-order",
    layout: FullLayout,
    component: DashboardKanbanOrder,
    exact: true,
  },
  {
    path: "/dashboard-kanban-receiving",
    layout: FullLayout,
    component: DashboardKanbanReceiving,
    exact: true,
  },
  {
    path: "/dashboard-kanban-pending",
    layout: FullLayout,
    component: DashboardKanbanPending,
    exact: true,
  },

  //Master Data

  {
    path: "/master-user-internal",
    layout: FullLayout,
    component: MasterUserInternal,
    exact: true,
  },
  {
    path: "/master-forecast",
    layout: FullLayout,
    component: MasterWeeklyForecast,
    exact: true,
  },
  {
    path: "/master-user-internal/create",
    layout: FullLayout,
    component: CreateMasterUserInternal,
    exact: true,
  },
  {
    path: "/master-user-internal/edit/:id",
    layout: FullLayout,
    component: EditMasterUserInternal,
    exact: true,
  },
  {
    path: "/master-user-external",
    layout: FullLayout,
    component: MasterUserExternal,
    exact: true,
  },
  {
    path: "/master-user-external/create",
    layout: FullLayout,
    component: CreateMasterUserExternal,
    exact: true,
  },
  {
    path: "/master-user-external/edit/:id",
    layout: FullLayout,
    component: EditMasterUserExternal,
    exact: true,
  },
  {
    path: "/master-supplier",
    layout: FullLayout,
    component: MasterSupplier,
    exact: true,
  },

  {
    path: "/master-customer",
    layout: FullLayout,
    component: MasterCustomer,
    exact: true,
  },

  {
    path: "/master-item",
    layout: FullLayout,
    component: MasterItem,
    exact: true,
  },

  {
    path: "/master-kanban",
    layout: FullLayout,
    component: MasterKanban,
    exact: true,
  },
  {
    path: "/master-kanban/create",
    layout: FullLayout,
    component: CreateMasterKanban,
    exact: true,
  },
  {
    path: "/master-kanban/edit/wip/:id",
    layout: FullLayout,
    component: EditKanbanWIP,
    exact: true,
  },
  {
    path: "/master-kanban/edit/finish-goods/:id",
    layout: FullLayout,
    component: EditKanbanFinishGoods,
    exact: true,
  },
  {
    path: "/master-kanban/edit/supplier/:id",
    layout: FullLayout,
    component: EditKanbanSupplier,
    exact: true,
  },
  {
    path: "/master-warehouse-location",
    layout: FullLayout,
    component: MasterWarehouseLocation,
    exact: true,
  },
  {
    path: "/master-warehouse-location/create",
    layout: FullLayout,
    component: CreateMasterWarehouseLocation,
    exact: true,
  },
  {
    path: "/master-warehouse-location/:id",
    layout: FullLayout,
    component: EditMasterWarehouseLocation,
    exact: true,
  },

  {
    path: "/master-product-structure",
    layout: FullLayout,
    component: MasterProductStructure,
    exact: true,
  },

  {
    path: "/master-grouping",
    layout: FullLayout,
    component: MasterGrouping,
    exact: true,
  },
  {
    path: "/master-grouping/create",
    layout: FullLayout,
    component: CreateMasterGrouping,
    exact: true,
  },
  {
    path: "/master-grouping/:id",
    layout: FullLayout,
    component: EditMasterGrouping,
    exact: true,
  },

  {
    path: "/master-shift",
    layout: FullLayout,
    component: MasterShift,
    exact: true,
  },
  {
    path: "/master-shift/create",
    layout: FullLayout,
    component: CreateMasterShift,
    exact: true,
  },
  {
    path: "/master-shift/:id",
    layout: FullLayout,
    component: EditMasterShift,
    exact: true,
  },
  //End Master Data

  //Start Supplier Portal
  {
    path: "/history-receiving",
    layout: FullLayout,
    component: SPHistoryReceiving,
    exact: true,
  },
  {
    path: "/scan-receiving-material",
    layout: FullLayout,
    component: SPScanReceivingMaterial,
    exact: true,
  },
  {
    path: "/scan-outgoing-internal",
    layout: FullLayout,
    component: SPScanOutgoingInternal,
    exact: true,
  },
  {
    path: "/history-outgoing-internal",
    layout: FullLayout,
    component: SPSHistoryOutgoingInternal,
    exact: true,
  },
  {
    path: "/scan-outgoing-supplier",
    layout: FullLayout,
    component: SPScanOutgoingSupplier,
    exact: true,
  },
  {
    path: "/history-outgoing-supplier",
    layout: FullLayout,
    component: SPHistoryOutgoingSupplier,
    exact: true,
  },
  {
    path: "/details-scan-outgoing-supplier/:id",
    layout: FullLayout,
    component: DetailsScanOutgoingSupplier,
    exact: true,
  },

  {
    path: "/kanban-generate",
    layout: FullLayout,
    component: SPKanbanGenerate,
    exact: true,
  },

  {
    path: "/kanban-generate/edit/:id",
    layout: FullLayout,
    component: EditSPKanbanGenerate,
    exact: true,
  },
  {
    path: "/production-request",
    layout: FullLayout,
    component: SPProductionRequest,
    exact: true,
  },

  {
    path: "/material-order",
    layout: FullLayout,
    component: SPMaterialOrder,
    exact: true,
  },

  {
    path: "/material-order/data",
    layout: FullLayout,
    component: SPMaterialOrderNonPO,
    exact: true,
  },

  // {
  //   path: "/details-material-order",
  //   layout: FullLayout,
  //   component: SPDetailsMaterialOrder,
  //   exact: true,
  // },
  {
    path: "/material-order/details/:NoPo",
    layout: FullLayout,
    component: SPDetailsMaterialOrder,
    exact: true,
  },
  {
    path: "/material-order/edit/:id",
    layout: FullLayout,
    component: EditSPDetailsMaterialOrder,
    exact: true,
  },
  {
    path: "/dn-material",
    layout: FullLayout,
    component: SPDNMaterial,
    exact: true,
  },
  {
    path: "/dn-material/create",
    layout: FullLayout,
    component: CreateSPDNMaterial,
    exact: true,
  },
  {
    path: "/dn-material/edit/:id",
    layout: FullLayout,
    component: EditSPDNMaterial,
    exact: true,
  },
  {
    path: "/dn-material/confirm/:id",
    layout: FullLayout,
    component: ConfirmSPDNMaterial,
    exact: true,
  },
  {
    path: "/gr-material",
    layout: FullLayout,
    component: SPListGRMaterial,
    exact: true,
  },
  {
    path: "/matching",
    layout: FullLayout,
    component: SPMatching,
    exact: true,
  },
  {
    path: "/history-matching",
    layout: FullLayout,
    component: SPHistoryMatching,
    exact: true,
  },
  //End Supplier Portal

  //Start Warehouse

  {
    path: "/scan-receiving",
    layout: FullLayout,
    component: WScanReceiving,
    exact: true,
  },
  {
    path: "/details-scan-receiving/:id",
    layout: FullLayout,
    component: DetailsScanReceiving,
    exact: true,
  },
  {
    path: "/scan-stock-opname",
    layout: FullLayout,
    component: ScanStockOpname,
    exact: true,
  },
  {
    path: "/history-stock-opname",
    layout: FullLayout,
    component: HistoryStockOpname,
    exact: true,
  },
  //End Warehouse

  //Start Produksi
  {
    path: "/scan-produksi",
    layout: FullLayout,
    component: PScanProduksi,
    exact: true,
  },
  {
    path: "/details-scan-produksi/:id",
    layout: FullLayout,
    component: DetailsScanProduksi,
    exact: true,
  },
  {
    path: "/history-scan-produksi",
    layout: FullLayout,
    component: PHistoryScanProduksi,
    exact: true,
  },
  {
    path: "/history-scan-kanban-ng",
    layout: FullLayout,
    component: PHistoryScanKanbanNG,
    exact: true,
  },
  {
    path: "/history-scan-produksi/edit/:id",
    layout: FullLayout,
    component: EditHistoryScanProduksi,
    exact: true,
  },
  {
    path: "/scan-wip-result",
    layout: FullLayout,
    component: PScanWipResult,
    exact: true,
  },
  {
    path: "/print-wip-result",
    layout: FullLayout,
    component: PPrintWipResult,
    exact: true,
  },
  {
    path: "/scan-finish-goods",
    layout: FullLayout,
    component: PScanFinishGoods,
    exact: true,
  },
  {
    path: "/print-finish-goods",
    layout: FullLayout,
    component: PPrintFinishGoods,
    exact: true,
  },
  {
    path: "/history-wip-result",
    layout: FullLayout,
    component: PHistoryWipResult,
    exact: true,
  },
  {
    path: "/history-finish-goods",
    layout: FullLayout,
    component: PHistoryFGResult,
    exact: true,
  },
  //End Produksi

  //Start Preparation Delivery
  {
    path: "/upload-dn-material",
    layout: FullLayout,
    component: PDUploadDNMaterial,
    exact: true,
  },
  {
    path: "/upload-dn-material/edit/:id",
    layout: FullLayout,
    component: EditPDUploadDNMaterial,
    exact: true,
  },
  {
    path: "/scan-dn-manifest",
    layout: FullLayout,
    component: PDScanDNManifest,
    exact: true,
  },
  {
    path: "/history-dn-manifest",
    layout: FullLayout,
    component: PDHistoryDNManifest,
    exact: true,
  },
  //End Preparation Delivery

  //Start Report
  {
    path: "/report-tracebility",
    layout: FullLayout,
    component: RReportTracebility,
    exact: true,
  },
  {
    path: "/report-ekanban",
    layout: FullLayout,
    component: RReportEKanban,
    exact: true,
  },
  //End Report
  {
    path: "/permission",
    layout: FullLayout,
    component: Permission,
    exact: true,
  },
  {
    path: "/permission/create",
    layout: FullLayout,
    component: CreatePermission,
    exact: true,
  },
  {
    path: "/permission/edit/:id",
    layout: FullLayout,
    component: EditPermission,
    exact: true,
  },
  {
    path: "/permission/view/:id",
    layout: FullLayout,
    component: ViewPermission,
    exact: true,
  },
  {
    path: "/role",
    layout: FullLayout,
    component: Role,
    exact: true,
  },
  {
    path: "/role/create",
    layout: FullLayout,
    component: CreateRole,
    exact: true,
  },
  {
    path: "/role/edit/:id",
    layout: FullLayout,
    component: EditRole,
    exact: true,
  },
  {
    path: "/setting",
    layout: FullLayout,
    component: Setting,
    exact: true,
  },
  {
    path: "/setting/create",
    layout: FullLayout,
    component: CreateSetting,
    exact: true,
  },
  {
    path: "/setting/edit/:id",
    layout: FullLayout,
    component: EditSetting,
    exact: true,
  },
  {
    path: "/matrix",
    layout: FullLayout,
    component: Matrix,
    exact: true,
  },
  {
    path: "/matrix/create",
    layout: FullLayout,
    component: CreateMatrix,
    exact: true,
  },
  {
    path: "/matrix/edit/:id",
    layout: FullLayout,
    component: EditMatrix,
    exact: true,
  },
  {
    path: "/shipment",
    layout: FullLayout,
    component: MasterShipment,
    exact: true,
  },
  {
    path: "/stock-hk",
    layout: FullLayout,
    component: MStockHK,
    exact: true,
  },
  {
    path: "/history-printed-dn",
    layout: FullLayout,
    component: SPHistoryDNMaterial,
    exact: true,
  },
  {
    path: "/transform-before-after",
    layout: FullLayout,
    component: SPHistoryDNMaterial,
    exact: true,
  },
];
