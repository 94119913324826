import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Alert,
  Dropdown,
  Layout,
  DatePicker,
  Form,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  Typography ,
  message,
  Space,
  Upload,
  Avatar,
} from "antd";

import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./SPProductionRequest.less";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function SPProductionRequest(props) {
  const { Text } = Typography;
  const [keyword, setKeyword] = useState("");
  const [kanbans, setProd] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [keywordModal, setKeywordModal] = useState("");
  const [materials, setMaterials] = useState([]);
  const [materialPO, setMaterialPO] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPOVisible, setIsModalPOVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [data, setData] = useState([]);
  const [MergeDate, setMergeDate] = useState([]);
  const [dataNotif, setdataNotif] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [kanbanDetails, setKanbanDetails] = useState([]);
  const [kanbanDetailsTotal, setKanbanDetailsTotal] = useState(0);
  const [keywordDetails, setKeywordDetails] = useState("");
  const [error, setError] = useState(null);
  const [failedData, setFailedData] = useState([]);
  const [modalFailed, setFailedModal] = useState(0);
  const [sortModal, setSortModal] = useState({
    sort: "vendor_code",
    order: "ascend",
  });
  const [pageModal, setPageModal] = useState(1);
  const [pageSizeModal, setPagesizeModal] = useState(10);

  const [isModalNotif, setIsModalNotif] = useState(false);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedRowKeysDataPO, setSelectedRowKeysDataPO] = useState([]);
  const [selectedRowsDataPO, setSelectedRowsDataPO] = useState([]);
  const [generate, setGenerate] = useState({
    purchase_order: "",
  });

  const [printing, setPrinting] = useState(false);

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const handleSearchModal = (value) => {
    console.log(value);
    setKeywordModal(value);
    console.log(selected[0]);
    getDataPO(selected[0], value);
  };
  // const handleSetVal = (value) => {
  //   console.log(value);
  //   setKeywordModal(value);
  // };
  const changeDate  = (name,value) =>
  {
    if(value == null)
    {
      var date = null;

    } else{
      var date = moment(value).format("YYYY-MM-DD");
    }
    console.log(date)
    var selected = [];
    var suppliers = [];
    selectedRowsData.map((data, index) => {
      selected.push(data.component_item);
      suppliers.push(data.supplier_code);
    });
    if(value == null)
      {
        var date = null;
  
      } else{
        var date = moment(value).format("YYYY-MM-DD");
      }
    setSelected({ selected });
    getDataPO(selected,suppliers, "",date);
    setMergeDate( date);
  }

  
  const handleSubmit = () => {

    if(MergeDate == null)
    {
      message.error("Anda Belum Memilih Tanggal Pengiriman");
      return;
    }
    
    const selectedRowsDataFiltered = [];

    // Loop melalui data pada selectedRowsData
    selectedRowsData.forEach((data) => {
      const componentItem = data.component_item;

      // Mengecek apakah komponen item ada dalam selectedRowsDataPO
      const found = selectedRowsDataPO.some(
        (dataPO) => dataPO.component_item === componentItem
      );

      // Jika ditemukan, tambahkan data dari selectedRowsData dan selectedRowsDataPO
      if (found) {
        const matchingDataPO = selectedRowsDataPO.find(
          (dataPO) => dataPO.component_item === componentItem
        );

        // Membuat salinan data dengan tambahan purchase_order
        const combinedData = {
          ...data,
          purchase_order: matchingDataPO.purchase_order,
        };
        selectedRowsDataFiltered.push(combinedData);
      }
    });

    if (selectedRowKeysDataPO.length == 0) {
      message.error("Anda Belum Memilih Data");
    } else {
      setLoading(true)
      Axios.post(
        `${url}/production-request-merge`,
        {
          children: selectedRowsDataFiltered,
          tgl_pengiriman: MergeDate,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          closeModal();
          setLoading(false)
          getData();
          props.history.push("/dn-material");
        })
        .catch((err) => {
          if (err.response) {
            setIsModalNotif(true);
            setdataNotif(err.response.data.items);
            message.error(err.response.data.message);
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          }
          setLoading(false);
        });
    }
  };
  const handleReset = () => {
    setData({});
    setSelectedRowKeysDataPO([]);
    setSelectedRowsDataPO([]);
    changeDate('tgl_pengiriman',null);
  };
  const handleResetPO = () => {
    setData({});
    setSelectedRowKeysData([]);
  };

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getKanbanDetails();
      }
    } else {
      setPage(1);
      setData([]);
      setKeyword(value);

      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData(keyword, page, sort);
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getKanbanDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/production-request`, {
      params: {
        keyword,
        columns: [
          "month",
          "years",
          "supplier_name",
          "po_number",
          "publisher_date",
          "delivery_request_date",
          "component_item",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        setProd(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getKanbanDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/production-request/generate/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["item_code"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbanDetails(res.data.data);
        setKanbanDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };
  const showModalPO = () => {
    if (selectedRowKeysData.length == 0) {
      message.error("Anda Belum Memilih Data");
    } else {
      var selected = [];
      var suppliers = [];
      selectedRowsData.map((data, index) => {
        selected.push(data.component_item);
        suppliers.push(data.supplier_code);
      });
    
      setMergeDate(null)
      setSelected({ selected });
      getDataPO(selected,suppliers, "");
    }
  };
  
  const getDataPO = (selected,suppliers, keyword,date='now') => {
    setLoadingModal(true);
    // console.log('TES3T', keywordModal)
    // setKeywordModal(selected['selected']);
    Axios.get(`${url}/production-request/master-material-order`, {
      params: {
        keyword: keyword,
        columns: ["purchase_order", "component_item",'supplier_code'],
        component_item: selected,
        supplier_code: suppliers,
        perpage: pageSize,
        page,
        sort: sortModal.sort,
        order: sort.order,
        date: date,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setMaterialPO(res.data);
        setIsModalPOVisible(true);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadingModal(false);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
    setIsModalPOVisible(false);
    setIsModalNotif(false);
    setFailedModal(false);
    setMergeDate(null)
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/production-request/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteAll = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDeleteAll();
      },
    });
  };
  const [deleteAll, setDeleteAll] = useState([]);
  const confirmDeleteAll = () => {
    setLoading(true);
    Axios.post(
      `${url}/production-request/delete-all`,
      {
        remove: selectedRowKeysData.map((x) => {
          return {
            _id: x,
          };
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/production-request/template`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Production Request" + ".xlsx");
    });
  };
  const handleTableModalChange = (pagination, filters, sorter) => {
    setPageModal(pagination.current);
    console.log(sorter.field);
    setPagesizeModal(pagination.pageSize);
    setSortModal({ ...sortModal, sort: sorter.field, order: sorter.order });
  };
  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/production-request/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // console.log('a',res.data.data );
        if(res.data.failed > 0)
        {
          message.warning(res.data.message);
          setFailedData(res.data.data)
          setFailedModal(1)
        } else{
          message.success(res.data.message);
          closeModal();
        }
        getData();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          message.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columnsFailed = [
  
    {
      title: "Item Number",
      dataIndex: "item_number",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    
    {
      title: "Supplier",
      dataIndex: "supplier_code",
      key: "supplier_code",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    {
      title: "Storage",
      dataIndex: "storage",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    {
      title: "Message",
      dataIndex: "message",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
  ];
  const columnsNotif = [
    {
      title: 'No',
      key: 'index',
      width:50,
      render :(text, record, index) => index+1,
    },
    {
      title: "Item Number",
      dataIndex: "item_number",
      key: "item_number",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    {
      title: "Model / Project",
      dataIndex: "model",
      key: "model",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
  ];
  const columnsMerge = [
    {
      title: "Purchase Order",
      dataIndex: "purchase_order",
      key: "purchase_order",
      sorter: true,
      // defaultSortOrder: "ascend",
      // render: (text) => {
      //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
      // },
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      sorter: true,
    },
    {
      title: "Item Number",
      dataIndex: "component_item",
      sorter: true,
    },
  
    {
      title: "Quantity Ordered",
      dataIndex: "quantity_ordered",
      sorter: true,
    },
    {
      title: "Quantity Daily Order",
      dataIndex: "qty_daily_order",
      sorter: true,
    },
    {
      title: "Quantity Received",
      dataIndex: "quantity_received",
      sorter: true,
    },
    {
      title: "Quantity Open",
      dataIndex: "quantity_open",
      sorter: true,
    },
  ];

  const  disabledDate = (current) => {
    const today = new Date();

const formattedDate = today.getFullYear() + '-' +
  String(today.getMonth() + 1).padStart(2, '0') + '-' +
  String(today.getDate()).padStart(2, '0');

    let customDate = formattedDate;
    return current && current < moment(customDate, "YYYY-MM-DD");
  }
  const columns = [
    // {
    //   title: "Purchase Order",
    //   dataIndex: "purchase_order",
    //   key: "purchase_order",
    //   sorter: true,
    //   // defaultSortOrder: "ascend",
    //   // render: (text) => {
    //   //   return <Link to={`${pathname}/details/${text}`}>{text}</Link>;
    //   // },
    // },
    {
      title: "Item Number",
      dataIndex: "component_item",
      sorter: true,
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "model_parsed",
      sorter: true,
    },
    {
      title: "Group",
      dataIndex: "group_parsed",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "description1",
      sorter: true,
    },
    {
      title: "Part Number",
      dataIndex: "description2",
      sorter: true,
    },
    // {
    //   title: "Description 3",
    //   dataIndex: "description3",
    //   sorter: true,
    // },
    // {
    //   title: "Quantity Ordered",
    //   dataIndex: "quantity_ordered",
    //   sorter: true,
    // },
    {
      title: "Quantity Daily Order",
      dataIndex: "qty_daily_order",
      sorter: true,
    },
    // {
    //   title: "Quantity Received",
    //   dataIndex: "quantity_received",
    //   sorter: true,
    // },
    // {
    //   title: "Quantity Open",
    //   dataIndex: "quantity_open",
    //   sorter: true,
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Production Request yang gagal diimport "
        visible={modalFailed}
        width={1200}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <Table
            
              columns={columnsFailed}
              dataSource={failedData}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
              loading={loading}
            />
            <div>
              <Button onClick={closeModal}>
                Tutup
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Modal
        width={1200}
        title="PO Merge Info"
        visible={isModalNotif}
        footer={null}
        onCancel={closeModal}
      > 
        <Alert message="Tidak dapat melanjutkan merge PO, Item yang belum dibuatkan kanban" type="warning" />
      <Table
      columns={columnsNotif}
      rowKey={(data) => data.id}
      dataSource={dataNotif}
      // pagination={tableParams.pagination}
      loading={loading}
      // onChange={handleTableChange}
    />
        </Modal>
      <Modal
        width={1200}
        title="Data PO"
        visible={isModalPOVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Row type="flex" gutter={[10, 20]} justify="space-around">
                  <Col xs={24} md={12} lg={18}> 
                  {MergeDate != null ? ( <DatePicker placeholder="Tgl Pengiriaman" value={moment(MergeDate)} onChange={(value) => changeDate('tgl_pengiriman',value)} />):
                  ( <DatePicker  placeholder="Tgl Pengiriman" disabledDate={disabledDate} value={null}  onChange={(value) => changeDate('tgl_pengiriman',value)} />)}
                 
                </Col>
                  <Col xs={24} md={12} lg={6} className="align-right">
                    <Search
                      placeholder="Search ..."
                      // onChange={(value) => handleSetVal(value)}
                      onSearch={(value) => handleSearchModal(value)}
                    />
                  </Col>
                </Row>
                <Table
                  scroll={{ x: 1800 }}
                  columns={columnsMerge}
                  dataSource={materialPO.data}
                  rowKey={(data) => data._id}
                  onChange={handleTableModalChange}
                  pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: materialPO.total,
                    showSizeChanger: true,
                  }}
                  rowSelection={{
                    selectedRowKeys: selectedRowKeysDataPO,
                    type: "checkbox",
                    onChange: (selectedRowKeysPO, selectedRowsPO, record) => {
                      setSelectedRowsDataPO(selectedRowsPO);
                      setSelectedRowKeysDataPO(selectedRowKeysPO);
                      // console.log('PO TEST',selectedRowsPO)
                      const purchase_order = selectedRowsPO
                        ? selectedRowsPO[0]
                        : null;

                      setGenerate({
                        ...generate,
                        purchase_order,
                      });
                    },
                    getCheckboxProps: (record) => {
                      const checkDisable = selectedRowsDataPO
                      
                        ? selectedRowsDataPO[0]
                        : null;
                      
                      return {
                        disabled:
                        (checkDisable &&
                                (checkDisable.component_item+checkDisable.supplier_code) ==
                                record.component_item+record.supplier_code && checkDisable._id != record._id),
                      };
                    },

                    columnTitle: selectedRowsDataPO.length > 0 ? "" : <></>,
                  }}
                  loading={loadingModal}
                />
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    <Space>
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                        className="mr-button"
                      >
                        Merge PO 
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Fragment>
            </div>
          </div>
        </Card>
      </Modal>
      <Modal
        title="Import Production Request"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Pilih File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Unggah
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Batal
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Production Request"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button type="primary" onClick={showModal}>
                      <ImportOutlined />
                      Import
                    </Button>

                    <Button type="primary" onClick={downloadTemplate}>
                      <DownloadOutlined />
                      Download Template
                    </Button>
                    <Button type="primary" onClick={handleDeleteAll}>
                      <DeleteOutlined />
                      Hapus Data
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2000 }}
                    columns={columns}
                    dataSource={kanbans.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        const purchase_order = selectedRows
                          ? selectedRows[0]
                          : null;

                        setGenerate({
                          ...generate,
                          purchase_order,
                        });
                      },
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;
                          // console.log(selectedRowsData[0],'cc')
                        // return {
                        //   disabled:
                        //     (checkDisable &&
                        //       checkDisable.supplier_code !==
                        //         record.supplier_code) ||
                        //     record.status === 1,
                        // };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                    pagination={{
                      pageSize: pageSize,
                      total: kanbans.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col style={{ marginTop: 20 }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={showModalPO}
                      loading={loading}
                      className="mr-button"
                    >
                      Pilih PO
                    </Button>

                    <Button
                      onClick={handleResetPO}
                      type="default"
                      htmlType="button"
                      className="mr-button"
                    >
                      Reset
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPProductionRequest;
