/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  message,
  Modal,
  Space,
  Form,
  Table,
  Select,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { BarcodeOutlined } from "@ant-design/icons";

const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function SPScanOutgoingSupplier(props) {
  const [scanOutgoingSupplier, setScanOutgoingSupplier] = useState([]);
  const [dataTemporaryKanban, setDataTemporaryKanban] = useState([]);
  const [scanOutgoing, setScanOutgoing] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [supplier, setSupplier] = useState([]);
  const [dns, setDNSupplier] = useState([]);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [requiredSupplier, setRequiredSupplier] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [data, setData] = useState({
    kanban_number: "",
    nomor_kendaraan: "",
    supplier_id: null,
    nomor: "",
    no_po: "",
  });

  const [descInput, setDescInput] = useState([]);

  const selectSupplier = useRef();
  const selectDN = useRef();
  const barcode_kanbanRef = useRef();
  const nomorPo = useRef();
  const noKendaraanRef = useRef();

  const handleEnterKanbanNum = () => {
    nomorPo.current.focus();
  }
  const handleChange = (name, value) => {
    if (name === "no_po") {
      const addField = scanOutgoing.map((x) => ({ ...x, no_po: value }));
      setScanOutgoing(addField);
      setData({
        ...data,
        [name]: value,
      });
    } else if (name === "kanban_number") {
      setData({
        ...data,
        [name]: value,
      });
      
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
    setIsInputEmpty(value.trim() !== '');
  };

  const handlePressEnter = (input) => {
    if (input === "no_po") {
      noKendaraanRef.current.focus();
    }
  };

  useEffect(() => {
    getNumber();
    selectSupplier.current.focus();
  }, []);

  const scanNumberRef = useRef(null);

  const getNumber = () => {
    Axios.get(`${url}/scan-outgoing-supplier/get-number`, {
      params: {
        kanban_number: data.kanban_number,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }).then((res) => {
      setData({
        ...data,
        kanban_number: "",
        nomor_kendaraan: "",
        // supplier_id: null,
        nomor: res.data.data,
      });
    });
  };

  const getData = () => {
    const findFirst = dataTemporaryKanban.find(
      (x) => x.barcode === data.kanban_number
    );
    // const check = scanOutgoingSupplier.find(
    //   (x) => x.kanban_number === data.kanban_number
    // );
    console.log(findFirst);
    if (!findFirst) {
      Axios.get(`${url}/scan-outgoing-supplier/scan`, {
        params: {
          kanban_number: data.kanban_number,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.data === null) {
            Modal.error({
              title: "Failed!",
              content: "Not Found",
            });
            // message.error("Not Found");
            setData({
              ...data,
              kanban_number: "",
            });
            barcode_kanbanRef.current.focus();
          } else {
            const kanbanData = res.data.data;

            const checkHead = scanOutgoing.find(
              (x) => x.id_qad === kanbanData.id_qad
            );

            console.log(checkHead);

            if (checkHead) {
              setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
            } else {
              setScanOutgoing([...scanOutgoing, res.data.data]);
              setDataTemporaryKanban([...dataTemporaryKanban, res.data.data]);
            }
          }

          console.log("ini res", res);
          setData({
            ...data,
            kanban_number: "",
          });
          // setScanOutgoingSupplier([...scanOutgoingSupplier, res.data.data]);
          // props.history.push(`/details-scan-outgoing-supplier/${data.dn_no}`);
          // nomorPo.current.focus();
        })
        .catch((err) => {
          if (err.response) {
            setData({
              ...data,
              kanban_number: "",
            });
            Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
            
            if (err.response.status == 401) {
              sessionStorage.removeItem("token");
            }
          }
        });
    } else {
      Modal.error({
        title: "Failed!",
        content: "Kanban already scanned!",
      });
      setData({
        ...data,
        kanban_number: "",
      });
      barcode_kanbanRef.current.focus();
    }
  };

  console.log(scanOutgoing);
  console.log(dataTemporaryKanban);
  const printPdf = (id, cb) => {
    Axios.get(`${url}/histori-outgoing-supplier/print/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
      cb();
    });
  };

  const onSaveData = (isPrint = false) => {
    if (isDataSubmitted) {
      Modal.warning({
        title: "Warning!",
        content: "Data has already been submitted.",
      });
      return;
    }
    setLoading(true);
    // if(data.no_po === '') {
    //   message.error("Please insert data value Nomor PO, can not be empty");
    //   nomorPo.current.focus();
    // } else
    if (data.nomor_kendaraan === "") {
      Modal.error({
        title: "Failed!",
        content: "Please insert data value Nomor Kendaraan, can not be empty",
      });
      // message.error(
      //   "Please insert data value Nomor Kendaraan & Nomor PO, can not be empty"
      // );
      noKendaraanRef.current.focus();
    } else {
      Axios.post(
        `${url}/scan-outgoing-supplier`,
        {
          ...data,
          header: scanOutgoing,
          details: dataTemporaryKanban,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          if (isPrint) {
            // printPdf(res.data.data, () => {
            //   message.success(res.data.message);
            // });
            window.open(res.data.filename);
            props.history.push("/history-outgoing-supplier");
          } else {
            Modal.success({
              title: "Success!",
              content: res.data.message,
            });
            // message.success(res.data.message);
            props.history.push("/history-outgoing-supplier");
          }
          // getNumber();
          // handleReset();
          // setLoading(false);
          // setScanOutgoingSupplier([]);
          // if (data.parent_item) {
          //   message.success(res.data.message);
          // }
          setIsDataSubmitted(true);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response) {
            Modal.error({
              title: "Failed!",
              content: err.response.data.message,
            });
            // message.error(err.response.data.message);
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          }
          setLoading(false);
        });
    }

    setLoading(false);
  };

  const getVendor = async (keyword) => {
    await Axios.get(`${url}/master-supplier/getList`, {
      params: {
        keyword,
        columns: ["supplier_code", "supplier_name"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSupplier(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          Modal.error({
            title: "Failed!",
            content: err.response.data.message,
          });
          // message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };
  const getDN = async (keyword) => {
    var supplier = data.supplier_code;
    await Axios.get(`${url}/dn-material/getList`, {
      params: {
        supplier: supplier,
        keyword,
        columns: ["dn_no", "purchase_order",'supplier_code'],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDNSupplier(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          Modal.error({
            title: "Failed!",
            content: err.response.data.message,
          });
          // message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleReset = () => {
    console.log("reset");
    setData({
      ...data,
      kanban_number: "",
      nomor_kendaraan: "",
      supplier_id: null,
    });
    setScanOutgoing([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeSelectDN = (name, value) => {
      setData({
        ...data,
        [`${name}`]: value.value,
      });
  }

  const handleChangeSelectVendor = (name, value) => {
    const find = supplier.find((x) => x._id === value.key);
    setError(null);

    console.log("NOMOR", data.nomor);
    const splitDataNomor = data.nomor.split("/");
    const newNomor = [...splitDataNomor];
    newNomor[2] = find.supplier_code;
    const newUpdateNomor = newNomor.join("/");
    setRequiredSupplier(false);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`${name}_code`]: find.supplier_code,
      [`${name}_name`]: find.supplier_name,
      nomor: newUpdateNomor,
    });

    barcode_kanbanRef.current.focus();
  };

  const handleDelete = (id, data) => {
    console.log(id);
    const filterKanban = scanOutgoing.filter((x) => x._id.$oid !== id);
    const filterTempKanban = dataTemporaryKanban.filter(
      (x) => x.id_qad !== data.id_qad
    );
    console.log(filterKanban);
    setScanOutgoing(filterKanban);
    setDataTemporaryKanban(filterTempKanban);
  };

  const handleChangeDesc = (id, value) => {
    console.log(dataTemporaryKanban);
    console.log(id);

    setDataTemporaryKanban((prevData) => {
      // Membuat salinan baru dari dataTemporaryKanban
      const updatedData = [...prevData];

      // Mencari indeks elemen dengan ID yang sesuai
      const index = updatedData.findIndex((item) => item._id === id);

      // Jika elemen dengan ID yang sesuai ditemukan, update keterangan
      if (index !== -1) {
        updatedData[index].keterangan = value;
      }

      return updatedData;
    });

    setDescInput((prevDescInput) => {
      const existingIndex = prevDescInput.findIndex((item) => item.id === id);

      if (existingIndex !== -1) {
        const updatedDescInput = [...prevDescInput];
        updatedDescInput[existingIndex].desc = value;
        return updatedDescInput;
      } else {
        return [...prevDescInput, { id, desc: value }];
      }
    });
  };

  const onSaveDesc = (id) => {
    Axios.post(
      `${url}/scan-outgoing-supplier/scan/update-description/${id.$oid}`,
      {
        description: descInput.find((desc) => desc.id === id)?.desc,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        Modal.success({
          title: "Success!",
          content: res.data.message,
        })
        // message.success(res.data.message);
      })
      .catch((err) => {
        Modal.error({
          title: "Failed!",
          content: "Server Error",
        });
        
      });
  };

  const columns = [

    {
      title: "Item Number",
      dataIndex: "item_number",
      sorter: true,
    },
    {
      title: "PO",
      dataIndex: "no_po",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "model",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description_1",
      sorter: true,
    },
    {
      title: "Jumlah",
      dataIndex: "qty_kanban",
      sorter: true,
      render: (item, record) => {
        const findMatch = dataTemporaryKanban.filter(
          (x) => x.id_qad === record.id_qad
        );
        return `${findMatch.reduce(
          (total, num) => total + parseInt(num.qty_kanban),
          0
        )}`;
      },
    },

    {
      title: "Unit",
      dataIndex: "unit_of_measure",
      sorter: true,
    },
    {
      title: "Keterangan",
      dataIndex: "item_id",
      sorter: true,
      render: (id, record) => {
        const tempDesc =
          Object.keys(descInput).length > 0 &&
          descInput.find((desc) => desc.id === id)?.desc;
        return (
          <Input
            onPressEnter={() => onSaveDesc(id)}
            onChange={(e) => handleChangeDesc(record._id, e.target.value)}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "_id",
      render: (_id, data) => {
        console.log(_id.$oid);
        return (
          <Button
            onClick={() => handleDelete(_id ? _id.$oid : _id, data)}
            danger
          >
            Delete
          </Button>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title=" Scan Outgoing Supplier"
            bodyStyle={{ padding: "0" }}
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical">
                <Row>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Supplier :"
                        validateStatus={
                          error && error.supplier_id.length > 0 ? "error" : ""
                        }
                        help={
                          error && error.supplier_id.length > 0
                            ? error.supplier_id[0]
                            : ""
                        }
                      >
                        <Select
                          style={{ width: '99%', marginRight:10 }}
                          allowClear
                          labelInValue
                          ref={selectSupplier}
                          onChange={(value) =>
                            handleChangeSelectVendor("supplier", value)
                          }
                          onFocus={() => getVendor("")}
                          showSearch
                          onSearch={(value) => getVendor(value)}
                          filterOption={false}
                          value={
                            data.supplier_id && data.supplier_name
                              ? {
                                  key: data.supplier_id,
                                  label: data.supplier_label,
                                }
                              : {
                                  key: null,
                                  label: null,
                                }
                          }
                        >
                          {supplier &&
                            supplier.map((supplier) => {
                              return (
                                <Option value={supplier._id} key={supplier._id}>
                                  {`${supplier.supplier_code} - ${supplier.supplier_name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="DN :"
                        validateStatus={
                          error && error.supplier_id.length > 0 ? "error" : ""
                        }
                        help={
                          error && error.supplier_id.length > 0
                            ? error.supplier_id[0]
                            : ""
                        }
                      >
                        <Select
                          style={{ width: '99%' }}
                          allowClear
                          labelInValue
                          ref={selectDN}
                          onChange={(value) =>
                            handleChangeSelectDN("dn_no", value)
                          }
                          onFocus={() => getDN("")}
                          showSearch
                          onSearch={(value) => getDN(value)}
                          filterOption={false}
                          value={
                            data.dn_no 
                              ? {
                                  key: data.dn_no,
                                  label: data.dn_no,
                                }
                              : {
                                  key: null,
                                  label: null,
                                }
                          }
                        >
                          {dns &&
                            dns.map((dn) => {
                              return (
                                <Option value={dn._id} key={dn._id}>
                                  {`${dn.dn_no} `}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item label="Nomor :">
                        <Input
                          disabled
                          type="primary"
                          onChange={(e) =>
                            handleChange("nomor", e.target.value)
                          }
                          value={data.nomor}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item label="Scan Kanban :">
                        <Input
                        disabled={requiredSupplier}
                          onPressEnter={(e) => getData(e, "kanban_number")}
                          type="primary"
                          ref={barcode_kanbanRef}
                          prefix={<BarcodeOutlined />}
                          onChange={(e) =>
                            handleChange("kanban_number", e.target.value)
                          }
                          value={data.kanban_number}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        label="Nomor PO :"
                        validateStatus={
                          error && error.no_po.length > 0 ? "error" : ""
                        }
                        help={
                          error && error.no_po.length > 0 ? error.no_po[0] : ""
                        }
                      >
                        <Input
                          type="primary"
                          onChange={(e) =>
                            handleChange("no_po", e.target.value)
                          }
                          onPressEnter={() => handlePressEnter("no_po")}
                          ref={nomorPo}
                          disabled={scanOutgoing.length <= 0 ? true : false}
                          value={data.no_po}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        label="Nomor Kendaraan :"
                        validateStatus={
                          error && error.nomor_kendaraan.length > 0
                            ? "error"
                            : ""
                        }
                        help={
                          error && error.nomor_kendaraan.length > 0
                            ? error.nomor_kendaraan[0]
                            : ""
                        }
                      >
                        <Input
                          type="primary"
                          onChange={(e) =>
                            handleChange("nomor_kendaraan", e.target.value)
                          }
                          disabled={scanOutgoing.length <= 0 ? true : false}
                          ref={noKendaraanRef}
                          value={data.nomor_kendaraan}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Col xs={24}>
                  <Table
                    scroll={{ x: 1100 }}
                    columns={columns}
                    dataSource={scanOutgoing}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                  />
                </Col>
                <Row justify="start">
                  <Col style={{ marginTop: 20 }}>
                    {scanOutgoing.length > 0 && (
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => onSaveData(false)}
                          loading={loading}
                          disabled={data.nomor_kendaraan === "" ? true : false}
                          className="mr-button"
                        >
                          Submit
                        </Button>

                        <Button
                          type="primary"
                          onClick={() => onSaveData(true)}
                          loading={loading}
                          disabled={data.nomor_kendaraan === "" ? true : false}
                          className="mr-button"
                        >
                          Submit and Print
                        </Button>

                        <Button
                          onClick={handleReset}
                          type="default"
                          htmlType="button"
                          className="mr-button"
                        >
                          Reset
                        </Button>
                      </Space>
                    )}
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SPScanOutgoingSupplier;
